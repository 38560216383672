<template>
  <div class="credits">
    <a href="https://boitebeet.com" target="_blank" >
      <p>© 2024 - Restaurant Ophelia </p>
      <p> tous droits réservés </p>
      <p>{{ `${$t('beet.credits')} `}}</p>
    </a>
  </div>

</template>

<script>
export default {
	name: 'BeetCredits'
}
</script>
