<template>
  <div class="navbar" :class="{'-sticky': isSticky}">
      <RouterLink to="/" v-if="!isSticky">
        <img class="navbar__logo" src="/images/logo_blanc.svg">
      </RouterLink>

    <section>
      <div class="navbar__links">
        <template v-for="(item, key) in $beet.options.menus.main">
          <a @click="scrollTo(item.link)" v-if="item.link[0] === '#'" :key="key" class="navbar__link">
            {{item.label}}
          </a>

          <a v-else target="_blank" :href="item.link"  :key="`link-${key}`"  class="navbar__link">
            {{ item.label }}
          </a>
        </template>

        <button class="navbar__lang" >
          <button @click="switchLang('fr')">FR</button> /
          <button @click="switchLang('en')">EN</button> /
          <GoogleTranslate/>
        </button>
      </div>
    </section>

    <section v-if="isSticky" class="navbar__links -bottom">
      <img class="navbar__logo" src="/images/logo-half.svg" @click="scrollTop">
      <div class="navbar__favicon">
        <svg width="38px" height="36px" viewBox="0 0 38 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="ophelia-maquette-V2-1440px" transform="translate(-700.000000, -1002.000000)" fill="#E2E0D5">
              <g id="Nav-fond-foncé" transform="translate(593.000000, 930.000000)">
                <g id="Icone" transform="translate(107.000000, 72.000000)">
                  <polygon id="Fill-1" points="30.9582 12.6038 30.9582 22.8228 31.8932 22.2068 31.8932 11.9868"></polygon>
                  <polygon id="Fill-2" points="26.3745 16.9607 25.7265 17.8497 36.4775 17.8497 37.1255 16.9607"></polygon>
                  <polygon id="Fill-3" points="24.3999 18.8381 24.3999 29.0571 25.3349 28.4411 25.3349 18.2211"></polygon>
                  <polygon id="Fill-4" points="19.8162 23.195 19.1682 24.084 29.9182 24.084 30.5662 23.195"></polygon>
                  <polygon id="Fill-5" points="17.8416 25.0724 17.8416 35.2914 18.7766 34.6754 18.7766 24.4564"></polygon>
                  <polygon id="Fill-6" points="13.2588 29.4293 12.6108 30.3183 23.3608 30.3183 24.0088 29.4293"></polygon>
                  <polygon id="Fill-7" points="19.2834 0.0002 18.3484 0.6162 18.3484 10.8352 19.2834 10.2192"></polygon>
                  <polygon id="Fill-8" points="13.7646 4.9736 13.1166 5.8616 23.8666 5.8616 24.5146 4.9736"></polygon>
                  <polygon id="Fill-9" points="11.79 6.8501 11.79 17.0691 12.725 16.4541 12.725 6.2341"></polygon>
                  <polygon id="Fill-10" points="7.2063 11.2079 6.5583 12.0959 17.3083 12.0959 17.9573 11.2079"></polygon>
                  <polygon id="Fill-11" points="5.2317 13.0844 5.2317 23.3034 6.1667 22.6884 6.1667 12.4684"></polygon>
                  <polygon id="Fill-12" points="0.648 17.4422 0 18.3302 10.751 18.3302 11.399 17.4422"></polygon>
                  <polygon id="Fill-13" points="24.6528 6.6098 24.6528 16.8288 25.5878 16.2128 25.5878 5.9938"></polygon>
                  <polygon id="Fill-14" points="20.07 10.9667 19.421 11.8557 30.172 11.8557 30.82 10.9667"></polygon>
                  <polygon id="Fill-15" points="18.0954 12.8441 18.0954 23.0631 19.0294 22.4471 19.0294 12.2281"></polygon>
                  <polygon id="Fill-16" points="13.5117 17.201 12.8637 18.09 23.6137 18.09 24.2617 17.201"></polygon>
                  <polygon id="Fill-17" points="11.5371 19.0784 11.5371 29.2974 12.4721 28.6824 12.4721 18.4624"></polygon>
                  <polygon id="Fill-18" points="6.9534 23.4362 6.3054 24.3242 17.0554 24.3242 17.7044 23.4362"></polygon>
                </g>
              </g>
            </g>
          </g>
        </svg>

      </div>
      <div class="navbar__links">
        <a href="https://www.freebeespoints.com/fr/PointSale/Purchase/2433" target="_blank" class="navbar__external">{{$t('giftCard')}}<Icon id="oblique-arrow"/></a>
        <a href="https://booking.libroreserve.com/1fdb747c450789b/QC014870130389?affiliate-code=WEB&lang=en&restaurant-auto-select=true" target="_blank" class="navbar__external">{{ $t('reservation') }} <Icon id="oblique-arrow"/></a>
      </div>
    </section>

  </div>
</template>

<script>
import {LangRouterLink} from '@/components/global'
import GoogleTranslate from "../GoogleTranslate.vue";

export default {
	name: 'SiteNav',
	components: {GoogleTranslate, LangRouterLink},
  data() {
    return {
      scrollPosition: 0
    };
  },
	computed: {
		otherLang() {
			return this.$i18n.locale === 'fr' ? 'EN' : 'FR'
		},
    isSticky() {
      return this.scrollPosition > 10;
    }
	},
  methods: {
    scrollTo(id) {
      const elId = id.replace('#', '')
      const el = document.getElementById(elId)
      if (el) {
        const targetPosition = el.getBoundingClientRect().top + window.pageYOffset - 120;
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth'
        });
      }
    },
    updateScroll() {
      this.scrollPosition = window.scrollY || document.documentElement.scrollTop;
    },
    scrollTop() {
      window.scrollTo({top: 0, behavior: 'smooth'})
    },
    switchLang(lang) {
      const href = window.location.href
      if(href.includes('translate.goog')) {
        const index = href.indexOf('-com')
        const domain = href.slice(0, index)
        const url = `${domain}.com`
        window.location.replace(`${url}/${lang === 'en' ? '/en' : ''}`)
      }
      this.$i18n.locale = lang
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll, {passive: true});
  },
  destroyed() {
    window.removeEventListener('scroll', this.updateScroll);
  }
}
</script>
