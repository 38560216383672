import { createStore } from 'vuex'

export default createStore({
  state: {
    navigation: ['menu midi', 'menu soir', 'expériences', 'établissements', 'carrières', 'contact']
  },
  getters: {
    navigation: state => state.navigation
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
