<template>
  <span class="lang-switch">
    <span class="lang-switch__label" @click="open = !open"> + </span>

    <transition name="fade" mode="out-in" appear>
      <ul class="lang-switch__list" v-if="open">
        <li @click="open = false" class="lang-switch__close"> X</li>
        <template v-for="lang in languages">
          <li v-if="lang.key === 'empty'" style="height: 3rem"></li>
         <li v-else class="lang-switch__list--item" :key="lang.key" @click="translate(lang.key)">
          {{ lang.label }}
          </li>
        </template>

      </ul>
    </transition>


  </span>
</template>

<script>
import DropDown from "./global/DropDown.vue";

export default {
  name: "GoogleTranslate",
  components: {DropDown},
  data() {
    return {
      open: false,
      languages: [
        {label: 'Spanish', key: 'es'},
        {label: 'Chinese (Simplified)', key: 'zh-CN'},
        {label: 'Chinese (Traditional)', key: 'zh-TW'},
        {label: 'empty', key: 'empty'},
        {label: 'Afrikaans', key: 'af'},
        {label: 'Albanian', key: 'sq'},
        {label: 'Amharic', key: 'am'},
        {label: 'Arabic', key: 'ar'},
        {label: 'Armenian', key: 'hy'},
        {label: 'Assamese', key: 'as'},
        {label: 'Aymara', key: 'ay'},
        {label: 'Azerbaijani', key: 'az'},
        {label: 'Bambara', key: 'bm'},
        {label: 'Basque', key: 'eu'},
        {label: 'Belarusian', key: 'be'},
        {label: 'Bengali', key: 'bn'},
        {label: 'Bhojpuri', key: 'bho'},
        {label: 'Bosnian', key: 'bs'},
        {label: 'Bulgarian', key: 'bg'},
        {label: 'Catalan', key: 'ca'},
        {label: 'Cebuano', key: 'ceb'},
        {label: 'Chichewa', key: 'ny'},
        {label: 'Corsican', key: 'co'},
        {label: 'Croatian', key: 'hr'},
        {label: 'Czech', key: 'cs'},
        {label: 'Danish', key: 'da'},
        {label: 'Dhivehi', key: 'dv'},
        {label: 'Dogri', key: 'doi'},
        {label: 'Dutch', key: 'nl'},
        {label: 'Esperanto', key: 'eo'},
        {label: 'Estonian', key: 'et'},
        {label: 'Ewe', key: 'ee'},
        {label: 'Filipino', key: 'tl'},
        {label: 'Finnish', key: 'fi'},
        {label: 'Frisian', key: 'fy'},
        {label: 'Galician', key: 'gl'},
        {label: 'Georgian', key: 'ka'},
        {label: 'German', key: 'de'},
        {label: 'Greek', key: 'el'},
        {label: 'Guarani', key: 'gn'},
        {label: 'Gujarati', key: 'gu'},
        {label: 'Haitian Creole', key: 'ht'},
        {label: 'Hausa', key: 'ha'},
        {label: 'Hawaiian', key: 'haw'},
        {label: 'Hebrew', key: 'iw'},
        {label: 'Hindi', key: 'hi'},
        {label: 'Hmong', key: 'hmn'},
        {label: 'Hungarian', key: 'hu'},
        {label: 'Icelandic', key: 'is'},
        {label: 'Igbo', key: 'ig'},
        {label: 'Ilocano', key: 'ilo'},
        {label: 'Indonesian', key: 'id'},
        {label: 'Irish', key: 'ga'},
        {label: 'Italian', key: 'it'},
        {label: 'Japanese', key: 'ja'},
        {label: 'Javanese', key: 'jw'},
        {label: 'Kannada', key: 'kn'},
        {label: 'Kazakh', key: 'kk'},
        {label: 'Khmer', key: 'km'},
        {label: 'Kinyarwanda', key: 'rw'},
        {label: 'Konkani', key: 'gom'},
        {label: 'Korean', key: 'ko'},
        {label: 'Krio', key: 'kri'},
        {label: 'Kurdish (Kurmanji)', key: 'ku'},
        {label: 'Kurdish (Sorani)', key: 'ckb'},
        {label: 'Kyrgyz', key: 'ky'},
        {label: 'Lao', key: 'lo'},
        {label: 'Latin', key: 'la'},
        {label: 'Latvian', key: 'lv'},
        {label: 'Lingala', key: 'ln'},
        {label: 'Lithuanian', key: 'lt'},
        {label: 'Luganda', key: 'lg'},
        {label: 'Luxembourgish', key: 'lb'},
        {label: 'Macedonian', key: 'mk'},
        {label: 'Maithili', key: 'mai'},
        {label: 'Malagasy', key: 'mg'},
        {label: 'Malay', key: 'ms'},
        {label: 'Malayalam', key: 'ml'},
        {label: 'Maltese', key: 'mt'},
        {label: 'Maori', key: 'mi'},
        {label: 'Marathi', key: 'mr'},
        {label: 'Meiteilon (Manipuri)', key: 'mni-Mtei'},
        {label: 'Mizo', key: 'lus'},
        {label: 'Mongolian', key: 'mn'},
        {label: 'Myanmar (Burmese)', key: 'my'},
        {label: 'Nepali', key: 'ne'},
        {label: 'Norwegian', key: 'no'},
        {label: 'Odia (Oriya)', key: 'or'},
        {label: 'Oromo', key: 'om'},
        {label: 'Pashto', key: 'ps'},
        {label: 'Persian', key: 'fa'},
        {label: 'Polish', key: 'pl'},
        {label: 'Portuguese', key: 'pt'},
        {label: 'Punjabi', key: 'pa'},
        {label: 'Quechua', key: 'qu'},
        {label: 'Romanian', key: 'ro'},
        {label: 'Russian', key: 'ru'},
        {label: 'Samoan', key: 'sm'},
        {label: 'Sanskrit', key: 'sa'},
        {label: 'Scots Gaelic', key: 'gd'},
        {label: 'Sepedi', key: 'nso'},
        {label: 'Serbian', key: 'sr'},
        {label: 'Sesotho', key: 'st'},
        {label: 'Shona', key: 'sn'},
        {label: 'Sindhi', key: 'sd'},
        {label: 'Sinhala', key: 'si'},
        {label: 'Slovak', key: 'sk'},
        {label: 'Slovenian', key: 'sl'},
        {label: 'Somali', key: 'so'},
        {label: 'Sundanese', key: 'su'},
        {label: 'Swahili', key: 'sw'},
        {label: 'Swedish', key: 'sv'},
        {label: 'Tajik', key: 'tg'},
        {label: 'Tamil', key: 'ta'},
        {label: 'Tatar', key: 'tt'},
        {label: 'Telugu', key: 'te'},
        {label: 'Thai', key: 'th'},
        {label: 'Tigrinya', key: 'ti'},
        {label: 'Tsonga', key: 'ts'},
        {label: 'Turkish', key: 'tr'},
        {label: 'Turkmen', key: 'tk'},
        {label: 'Twi', key: 'ak'},
        {label: 'Ukrainian', key: 'uk'},
        {label: 'Urdu', key: 'ur'},
        {label: 'Uyghur', key: 'ug'},
        {label: 'Uzbek', key: 'uz'},
        {label: 'Vietnamese', key: 'vi'},
        {label: 'Welsh', key: 'cy'},
        {label: 'Xhosa', key: 'xh'},
        {label: 'Yiddish', key: 'yi'},
        {label: 'Yoruba', key: 'yo'},
        {label: 'Zulu', key: 'zu'}
      ]
    }
  },
  beforeMount() {
    if (window.location.href.indexOf(".translate.goog") > -1) {
      $('head').append('<style>iframe#gt-nvframe{display:none!important;}body{margin-top: 0px !important;}.o_language ul li a{font-size:11px; text-transform:uppercase !important;} footer button.notranslate{color:black !important;} button.notranslate{font-size:17px;}</style>');
    }
  },
  methods: {
    translate(lang) {
      window.location.href = 'https://restaurantophelia-com.translate.goog/en?_x_tr_sl=en&_x_tr_tl=' + lang + '&_x_tr_hl=fr-CA&_x_tr_pto=wapp';
    }
  }
}
</script>

